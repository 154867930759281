import MapHome from "../../components/Common/MapHome.vue";
import NewPositionSelection from "../../components/Common/NewPositionSelection.vue";
import NotificationTray from "../../components/Common/NotificationTray.vue";
import Testimonials from "../../components/Common/Testimonials.vue";

export default {
    install(Vue) {
        Vue.component('map-home', MapHome);
        Vue.component('new-position-selection', NewPositionSelection);
        Vue.component('notification-tray', NotificationTray);
        Vue.component('testimonials', Testimonials);
    }
}
