import Keyframes from '@keyframes/core';
import { ceil } from 'lodash';

$(() => {
    if (Keyframes.isSupported()) {
        let childrenCount = $('.slider .slide-track').children().length;

        Keyframes.define([{
            name: 'scroll',
            '0%': { 'transform': 'translateX(0)' },
            '100%': { 'transform': 'translateX(calc(-210px * ' + ceil(childrenCount / 2) + '))' }
        }]);

        let speed = ((childrenCount / 2) * 5) + 5;

        $('.slider .slide-track').css({
            'animation': 'scroll ' + speed + 's linear infinite',
            'width': 210 * childrenCount
        });

        $('.slider .slide-track').hover(
            function () {
                $(this).css({
                    'animation-play-state': 'paused',
                });
            },
            function () {
                $(this).css({
                    'animation-play-state': 'running',
                });
            }
        );
    }
});
