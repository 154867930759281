<template>
    <div class="d-flex">
        <div class="chiffres-cles col-md-12 col-lg-4 d-flex px-0">
            <div v-show="currentRegion === null || currentRegion.agency !== null">
                <div class="h1 color-blue-calypso">
                    <span v-if="currentRegion === null">Toute la France</span>
                    <span v-else>{{ currentRegion.name }}</span>
                </div>
                <div class="card card-stats shadow-none mb-3 bg-fuel-yellow--lighter">
                    <div class="pl-4 p-2 d-flex align-items-center">
                        <div class="h1 font-weight-bold color-fuel-yellow">
                            <span v-if="currentRegion === null">{{ numbers.organizationNb }}</span>
                            <span v-else-if="currentRegion.agency !== null">{{ currentRegion.agency.organizations_count }}</span>
                            </div>
                        <span class="ml-2 color-black">organismes coopérateurs</span>
                    </div>
                </div>
                <div class="card card-stats shadow-none mb-3 bg-dark-raspberry--lighter">
                    <div class="pl-4 p-2 d-flex align-items-center">
                        <div class="h1 font-weight-bold color-dark-raspberry">
                        <span v-if="currentRegion === null">{{ numbers.centerNb }}</span>
                        <span v-else-if="currentRegion.agency !== null">{{ currentRegion.agency.centers_count }}</span>
                        </div>
                        <span class="ml-2 color-black">établissements</span>
                    </div>
                </div>
                <div class="card card-stats shadow-none mb-3 bg-blue-calypso--lighter">
                    <div class="pl-4 p-2 d-flex align-items-center">
                        <div class="h1 font-weight-bold color-caribe">
                        <span v-if="currentRegion === null">{{ numbers.jobNb }}</span>
                        <span v-else-if="currentRegion.agency !== null">{{ currentRegion.agency.jobs_count }}</span>
                        </div>
                        <span class="ml-2 color-black">offres d'emploi</span>
                    </div>
                </div>
                <div class="card card-stats shadow-none mb-3 bg-finger-banana--lighter">
                    <div class="pl-4 p-2 color-finger-banana d-flex align-items-center">
                        <div class="h1 font-weight-bold">
                            <span v-if="currentRegion === null">{{ numbers.applicantNb }}</span>
                            <span v-else-if="currentRegion.agency !== null">{{ currentRegion.agency.applicants_count }}</span>
                        </div>
                        <span class="ml-2 color-black">intérimaires</span>
                    </div>
                </div>
            </div>
            <div>
                <div v-if="currentRegion !== null && currentRegion.agency === null">
                    <div class="mb-3 d-flex justify-content-center">
                        <div class="card card--height card-border-radius-lg shadow-none card--padding bg-caribe text-white position-relative p-5 ">
                            <div class="d-flex flex-row">
                                <img src="../../../images/pictos/drawing-pin.png" class="drawing-pin" title="Icône punaise">
                                <div>
                                    <img src="../../../images/pictos/logo-e.png" class="logo-e">
                                </div>
                                <div class="d-flex flex-column">
                                    <span class="h3">{{ currentRegion.name }}</span>
                                    <span>Nous ne sommes pas encore ici !</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="map-region--image col-lg-8">
            <div class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:amcharts="http://amcharts.com/ammap" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="585" viewbox="0 0 612 585">
                    <g>
                        <template v-for="region in regions">
                            <path
                                :class="{
                                    'is-active': currentRegion !== null && currentRegion.id === region.id,
                                    'color-finger-banana': region.agency !== null,
                                }"
                                :title="region.name"
                                :id="region.slug"
                                :d="region.svg_shape"
                                @mouseover="currentRegion = region"
                                @mouseout="currentRegion = null"
                                @click="redirectRegion(region)"
                            />
                        </template>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MapHome',

        data() {
            return {
                currentRegion: null,
                hasAgency: false,
                errors: [],
            }
        },

        props: {
            regions: {
                type: Array,
                default() {
                    return [];
                },
            },
            numbers: {
                type: Object,
                default() {
                    return {
                        applicantNb: 0,
                        centerNb: 0,
                        jobNb: 0,
                        organizationNb: 0,
                    }
                },
            },
        },

        methods: {
            redirectRegion(region) {
                if (region.agency !== null) {
                    window.location = route('public.region.show', [region.slug]);
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    .bg-rhubarb-pie--lighter {
      background-color:#fcf3f5;
    }

    .bg-blue-calypso--lighter {
      background-color:#ecf7f8;
    }

    .bg-finger-banana--lighter {
      background-color:#fdfae5;
    }

    .drawing-pin {
        position: absolute;
        top: -5px;
        left: 0;
        width: 60px;
    }

    .card--height {
        min-height: 160px;
    }

    .logo-e{
        max-width: 60px;
        margin-right: 10px;
    }
</style>
