<template>
    <div>
        <div class="container mb-3 d-flex justify-content-end align-items-center filters">
            <div class="d-flex align-items-center">
                <input type="text" class="search-input p-1 ml-md-5" v-model="searchText" placeholder="Recherchez une fiche métier">
                <i class="ml-1 fa-solid fa-magnifying-glass h2"></i>
            </div>
        </div>
        <div class="d-flex justify-content-around flex-wrap">
            <div class="col-12 col-md-3 card card-stats shadow-none mb-3 bg-fuel-yellow--lighter">
                <div class="pl-4 p-2 color-fuel-yellow d-flex align-items-center">
                    <div class="h1 font-weight-bold color-fourth">
                        <span>{{jobDescriptionNb}}</span>
                    </div>
                    <span class="ml-2 color-black">Fiches métiers</span>
                </div>
            </div>
            <div class="col-12 col-md-3 card card-stats shadow-none mb-3 bg-dark-raspberry--lighter">
                <div class="pl-4 p-2 d-flex align-items-center">
                    <div class="h1 font-weight-bold color-dark-raspberry">
                        <span>{{testimonialNb}}</span>
                    </div>
                    <span class="ml-2 color-black">témoignages</span>
                </div>
            </div>
            <div class="col-12 col-md-3 card card-stats shadow-none mb-3 bg-caribe--lighter">
                <div class="pl-4 p-2 d-flex align-items-center">
                    <div class="h1 font-weight-bold color-caribe">
                        <span>{{jobNb}}</span>
                    </div>
                    <span class="ml-2 color-black">offres d'emploi</span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class=" m-md-5" v-for="job in filteredJobList" :key="job.id" ref="index">
                <div class="row d-flex">
                    <div class="card col-md-8 col-12 mb-2 p-0" >
                        <div v-if="!selectedTestimonial || selectedTestimonialJobId != job.id" class="d-flex justify-content-between flex-column h-100">
                            <div class="p-3">
                                <h2 class="h3 text-center mt-3">
                                    <span class="position-relative">
                                        <img class="decorating-title mt-1" :src="require('../../../images/pictos/decorating-title.svg')" alt="Demi cercle décoratif Ettic" />
                                        {{job.name}}
                                    </span>
                                </h2>
                                <p v-html="job.description"></p>
                                <div class="h5">
                                    <i class="fa-solid fa-bullseye text-primary"></i> Activités
                                </div>
                                <p v-html="job.activity"></p>
                                <div class="h5">
                                    <span><i class="fa-solid fa-graduation-cap text-primary"></i></span> Diplômes et Pré-requis
                                </div>
                                <p v-html="job.training"></p>
                            </div>
                            <div v-if="job.media.length" class="bg-dark-raspberry--lighter d-flex justify-content-center btn"><a :href="job.media[0].original_url"><i class="fa-solid fa-download"></i> Télécharger la fiche complète</a></div>
                        </div>
                        <div v-if="selectedTestimonial && selectedTestimonialJobId == job.id">
                            <div class="p-3">
                                <div class="text-center mt-3">
                                    <h2 class="h3 text-center mt-3">
                                        <span class="position-relative">
                                            <img class="decorating-title mt-1" :src="require('../../../images/pictos/decorating-title.svg')" alt="Demi cercle décoratif Ettic" />
                                            {{selectedTestimonial.name}}
                                        </span>
                                    </h2>
                                    <span class="h4">{{selectedTestimonial.position}}</span>
                                </div>
                                <p v-html="selectedTestimonial.content"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 d-flex d-md-block cards-testimonials">
                        <div v-for="testimonial in job.testimonials" :key="testimonial.id" >
                            <div class="card-flip mb-3 ml-lg-5" v-if="!selectedTestimonial || selectedTestimonial.id != testimonial.id">
                                <div class="content" @click="readTestimonial(job,testimonial)">
                                    <div class="front bg-finger-banana">
                                        <div><strong class="h3">{{testimonial.name}}</strong></div>
                                        <div class="h6">{{testimonial.position}}</div>
                                    </div>
                                    <div class="back bg-caribe p-4">
                                      <span v-if="!mobile" v-html="testimonial.content.slice(0, 100)"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="selectedTestimonial && selectedTestimonialJobId == job.id">
                            <div class="card-flip mb-3 ml-lg-5">
                                <div class="content" @click="reset()">
                                    <div class="front bg-caribe">
                                        <div><strong class="h3">{{job.name}}</strong></div>
                                    </div>
                                    <div class="back bg-finger-banana p-3">
                                        <div><strong class="h3">Fiche métier</strong></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-5">
            </div>
        </div>
    </div>
</template>


<script>
import { ref } from "vue";
import axios from 'axios';

export default {
    name: 'Testimonials',
    data() {
        return{
            selectedTestimonial: null,
            selectedTestimonialJobId: null,
            mobile: null,
            jobs: [],
            jobDescriptionNb: 0,
            testimonialNb: 0,
            jobNb: 0,
        }
    },
    props: {
        searchText: {
            type: String,
            default: '',
        },
    },
    computed: {
        filteredJobList() {
            return this.jobs.filter(job => job.name.toLowerCase().includes(this.searchText.toLowerCase()));
        },
    },
    methods: {
        readTestimonial(job,testimonial){
            this.selectedTestimonial = testimonial;
            this.selectedTestimonialJobId = job.id
            if (screen.width < 768){
                this.mobile = true
            }
        },
        reset() {
            this.selectedTestimonial = null;
        },
        getTestimonials() {
            axios.get(route('api.public.testimonials.index'))
                .then(response => {
                    this.jobs = response.data.jobs
                    this.jobDescriptionNb = response.data.jobDescriptionNb
                    this.testimonialNb = response.data.testimonialNb
                    this.jobNb = response.data.jobNb
                    this.jobs.forEach(job => {
                      job.show = true;
                    });
                })
                .catch(e => {
                    this.errors.push(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted: function() {
        this.getTestimonials()
    }
}
</script>

<style scoped lang="scss">
    .card-flip {
      top: 50%;
      left: 50%;
      width: 250px;
      height: 250px;
      perspective: 500px;
    }

    .content {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 200px;
      box-shadow: 0 0 15px rgba(0,0,0,0.1);
      transition: transform 1s;
      transform-style: preserve-3d;
    }

    .card-flip:hover .content {
      transform: rotateY( 180deg ) ;
      transition: transform 0.5s;
      cursor: pointer;
    }

    .front,.back {
      position: absolute;
      height: 100%;
      width: 100%;
      color: white;
      background-color: white;
      text-align: center;
      border-radius: 200px;
      backface-visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .back {
      color: white;
      transform: rotateY( 180deg );
    }

    @media screen and (max-width: 767px) {
        .card-flip{
            width: 120px;
            height: 120px;
        }

        input[type="text"]{
            width:100%;
        }

        .cards-testimonials{
            justify-content:space-evenly;
        }

        .content{
            font-size:10px;
        }
    }

    .search-input{
        border-radius: 30px;
        border-color: #dcdcdc;
        border-style: solid;
        min-width: 250px;
    }

    .fa-magnifying-glass{
        color: #dcdcdc;
    }

    hr {
        border-top: 1px dashed #148181;
    }

    .btn{
        a{
            color: black;
        }
    }
</style>
