import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

import './common/bootstrap';
import './common/select2';
import './common/submit-button';
import './common/links';
import './common/show-password';
import './public/contact';
import './public/slider';
import './public/faq';

import Vue from 'vue';
import registerPlugins from '@publicPlugins';

window.Vue = Vue;

registerPlugins(Vue);

Vue.mixin({
    methods: {
        route: route,
    },
});

new Vue({
    el: '#app',
    components: { VueperSlides, VueperSlide },
});
